import logo from "./logo.svg";
import "./App.css";
import DashboardTable from "./Components/DashboardTable";
import LinearProgressWithLabel from "./Components/ProgressBar";
import ButtonAppBar from "./Components/NavBar.js";
import firebase, { db, auth, functions } from "./utils/firebase";
import { useEffect, useState } from "react";
import LoginPage from "./Components/LoginPage";
import { Link, Route, Switch } from "react-router-dom";
import UserPage from "./pages/user-page/UserPage";
import WeeklyMoversTable from "./Components/WeeklyMoversTable";
import QuizzesPage from "./pages/QuizzesPage/QuizzesPage";
import SurveysPage from "./pages/SurveysPage/SurveysPage";
import { CircularProgress } from "@mui/material";
import CodingKitsPage from "./pages/CodingKitsPage/CodingKitsPage.js";
import DatabasePage from "./pages/DatabasePage/DatabasePage";
import UserSubscriptions from "./pages/UserSubscriptions/UserSubscriptions";
import KnownIssuesPage from "./Components/KnownIssuesPage/KnownIssuesPage";
import ConsumersUsagePage from "./Components/ConsumersUsagePage/ConsumersUsagePage";
import TeamsActivityPage from "./pages/TeamsActivityPage/TeamsActivityPage";
import TeamDetailsPage from "./pages/TeamDetailsPage/TeamDetailsPage";
import { getEstimatedServerTimeInMilliseconds } from "./utils/Helpers";
//added
//import { ChakraProvider } from '@chakra-ui/react'

document.body.style = "background: #d0f2ee;";

function App() {
  const [hasLoadedUser, setHasLoadedUser] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
        const userId = user.uid;
        db.collection("users")
          .doc(userId)
          .get()
          .then((doc) => {
            const isAdminLocal = doc.data().isAdmin;
            setIsAdmin(isAdminLocal);
            setHasLoadedUser(true);
          });
      } else {
        // No user is signed in.
        setIsAdmin(false);
        setHasLoadedUser(true);
      }
    });
  }, []);

  useEffect(() => {
    const main = async () => {
      const serverTime = await getEstimatedServerTimeInMilliseconds();
      console.log("serverTime", serverTime);
    };
    main();
  }, []);

  return (
    <div className="App">
      <h1 style={{ fontSize: "24px" }}>JuiceMind Dashboard</h1>
      <a href="/database">
        <div
          style={{
            padding: "10px 20px",
            fontSize: "20px",
            backgroundColor: "orange",
            color: "white",
            borderRadius: "5px",
            borderBottom: "3px solid brown",
            display: "inline-block",
            cursor: "pointer",
            position: "absolute",
            left: "50px",
            top: "20px",
          }}
        >
          Database
        </div>
      </a>
      <a href="/consumers">
        <div
          style={{
            padding: "10px 20px",
            fontSize: "20px",
            backgroundColor: "orange",
            color: "white",
            borderRadius: "5px",
            borderBottom: "3px solid brown",
            display: "inline-block",
            cursor: "pointer",
            position: "absolute",
            right: "450px",
            top: "20px",
          }}
        >
          Consumers
        </div>
      </a>
      <a href="/consumers-usage">
        <div
          style={{
            padding: "10px 20px",
            fontSize: "20px",
            backgroundColor: "orange",
            color: "white",
            borderRadius: "5px",
            borderBottom: "3px solid brown",
            display: "inline-block",
            cursor: "pointer",
            position: "absolute",
            right: "605px",
            top: "20px",
          }}
        >
          Consumers Usage
        </div>
      </a>
      <a href="/quizSurveys">
        <div
          style={{
            padding: "10px 20px",
            fontSize: "20px",
            backgroundColor: "orange",
            color: "white",
            borderRadius: "5px",
            borderBottom: "3px solid brown",
            display: "inline-block",
            cursor: "pointer",
            position: "absolute",
            right: "280px",
            top: "20px",
          }}
        >
          Quiz Surveys
        </div>
      </a>
      <a href="/quizzes">
        <div
          style={{
            padding: "10px 20px",
            fontSize: "20px",
            backgroundColor: "orange",
            color: "white",
            borderRadius: "5px",
            borderBottom: "3px solid brown",
            display: "inline-block",
            cursor: "pointer",
            position: "absolute",
            right: "150px",
            top: "20px",
          }}
        >
          Quizzes
        </div>
      </a>
      <a href="/userSubscriptions">
        <div
          style={{
            padding: "10px 20px",
            fontSize: "20px",
            backgroundColor: "orange",
            color: "white",
            borderRadius: "5px",
            borderBottom: "3px solid brown",
            display: "inline-block",
            cursor: "pointer",
            position: "absolute",
            right: "825px",
            top: "20px",
          }}
        >
          User Subscriptions
        </div>
      </a>
      <a href="/knownIssues">
        <div
          style={{
            padding: "10px 20px",
            fontSize: "20px",
            backgroundColor: "orange",
            color: "white",
            borderRadius: "5px",
            borderBottom: "3px solid brown",
            display: "inline-block",
            cursor: "pointer",
            position: "absolute",
            right: "1045px",
            top: "20px",
          }}
        >
          Known Issues
        </div>
      </a>
      <a href="/teams-activity">
        <div
          style={{
            padding: "10px 20px",
            fontSize: "20px",
            backgroundColor: "orange",
            color: "white",
            borderRadius: "5px",
            borderBottom: "3px solid brown",
            display: "inline-block",
            cursor: "pointer",
            position: "absolute",
            right: "300px",
            top: "20px",
          }}
        >
          Teams Activity
        </div>
      </a>
      <Link to="/">
        <div
          style={{
            padding: "10px 20px",
            fontSize: "20px",
            backgroundColor: "dodgerblue",
            color: "white",
            borderRadius: "5px",
            borderBottom: "3px solid blue",
            display: "inline-block",
            cursor: "pointer",
            position: "absolute",
            right: "40px",
            top: "20px",
          }}
        >
          Home
        </div>
      </Link>
      {!hasLoadedUser ? (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : isAdmin ? (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            textAlign: "center",
          }}
        >
          <Switch>
            <Route exact path="/user/:userId">
              <UserPage />
            </Route>
            <Route path="/quizSurveys">
              <SurveysPage />
            </Route>
            <Route path="/quizzes">
              <QuizzesPage />
            </Route>
            <Route path="/userSubscriptions">
              <UserSubscriptions />
            </Route>
            <Route path="/knownIssues">
              <KnownIssuesPage />
            </Route>
            <Route path="/consumers-usage">
              <ConsumersUsagePage />
            </Route>
            <Route path="/database">
              <DatabasePage />
            </Route>
            <Route exact path="/teams-activity">
              <TeamsActivityPage />
            </Route>
            <Route path="/teams-activity/:teamId">
              <TeamDetailsPage />
            </Route>
            <Route path="/">
              <CodingKitsPage />
            </Route>
          </Switch>
        </div>
      ) : (
        <LoginPage />
      )}
    </div>
  );
}

export default App;
