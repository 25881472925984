import React, { useEffect, useState } from "react";
import { db } from "../../utils/firebase";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Tooltip,
  TextField,
  Button,
  Chip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export default function TeamsActivityPage() {
  const [loading, setLoading] = useState(true);
  const [teamsActivity, setTeamsActivity] = useState([]);

  // Helper function to format date to local datetime-local input format
  const formatDateForInput = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  // Helper function to convert local datetime-local input to Date
  const parseInputToDate = (dateString) => {
    // Create a date object in local timezone
    const localDate = new Date(dateString);
    // The date is already in local timezone, no need for additional conversion
    return localDate;
  };

  // Form state (temporary values while user is selecting)
  const [formValues, setFormValues] = useState({
    aiGrading: "all",
    startDate: (() => {
      const date = new Date();
      date.setHours(date.getHours() - 1); // Default to last hour
      return formatDateForInput(date);
    })(),
    endDate: formatDateForInput(new Date()),
  });

  // Query parameters (only updated when search is triggered)
  const [queryParams, setQueryParams] = useState({
    aiGrading: formValues.aiGrading,
    startDate: formValues.startDate,
    endDate: formValues.endDate,
  });

  const fetchTeamsActivity = async (params) => {
    try {
      setLoading(true);
      const startTimestamp = parseInputToDate(params.startDate);
      const endTimestamp = parseInputToDate(params.endDate);

      console.log(
        "Start Date (local):",
        new Date(params.startDate).toLocaleString()
      );
      console.log(
        "End Date (local):",
        new Date(params.endDate).toLocaleString()
      );
      console.log("Start Timestamp (UTC):", startTimestamp.toUTCString());
      console.log("End Timestamp (UTC):", endTimestamp.toUTCString());

      let query = db
        .collection("teamsQuestionSubmissions")
        .orderBy("createdAt", "desc")
        .where("createdAt", ">=", startTimestamp)
        .where("createdAt", "<=", endTimestamp);

      if (params.aiGrading !== "all") {
        query = query.where("isAiGrading", "==", params.aiGrading === "true");
      }

      const submissionsSnapshot = await query.get();
      console.log("Number of submissions found:", submissionsSnapshot.size);
      if (submissionsSnapshot.size === 0) {
        console.log(
          "No submissions found in range. Sample submission timestamp:"
        );
        // Get one submission to compare timestamps
        const sampleQuery = await db
          .collection("teamsQuestionSubmissions")
          .orderBy("createdAt", "desc")
          .limit(1)
          .get();

        if (!sampleQuery.empty) {
          const sampleDoc = sampleQuery.docs[0];
          const sampleTimestamp = sampleDoc.data().createdAt;
          console.log(
            "Sample submission timestamp:",
            sampleTimestamp.toDate().toLocaleString()
          );
        }
      }

      const teamsMap = new Map();
      const submissionsCountMap = new Map();
      const tokenUsageMap = new Map();
      const teacherMap = new Map();
      const aLaCarteCoursesMap = new Map(); // Track unique courses per team

      for (const doc of submissionsSnapshot.docs) {
        const submission = doc.data();
        const teamId = submission.teamId;

        submissionsCountMap.set(
          teamId,
          (submissionsCountMap.get(teamId) || 0) + 1
        );

        // Track aLaCarteCourse from submission
        if (submission.aiGradeDetails?.aLaCarteDetails?.aLaCarteCourse) {
          if (!aLaCarteCoursesMap.has(teamId)) {
            aLaCarteCoursesMap.set(teamId, new Set());
          }
          aLaCarteCoursesMap
            .get(teamId)
            .add(submission.aiGradeDetails.aLaCarteDetails.aLaCarteCourse);
        }

        console.log("aLaCarteCoursesMap", aLaCarteCoursesMap);

        if (!tokenUsageMap.has(teamId)) {
          tokenUsageMap.set(teamId, { inputTokens: 0, outputTokens: 0 });
        }
        if (submission.aiGradeDetails?.usage) {
          const currentUsage = tokenUsageMap.get(teamId);
          tokenUsageMap.set(teamId, {
            inputTokens:
              currentUsage.inputTokens +
              (submission.aiGradeDetails.usage.inputTokens || 0),
            outputTokens:
              currentUsage.outputTokens +
              (submission.aiGradeDetails.usage.outputTokens || 0),
          });
        }

        if (!teamsMap.has(teamId)) {
          const teamDoc = await db.collection("teams").doc(teamId).get();
          if (teamDoc.exists) {
            const teamData = teamDoc.data();
            const teamMembers = teamData.teamMembers || {};
            let teacherName = null;

            // Find the teacher in teamMembers
            for (const [memberId, memberData] of Object.entries(teamMembers)) {
              if (memberId !== teamData.userId) continue;

              if (memberData && memberData.name) {
                teacherName =
                  memberData.name && memberData.name !== " "
                    ? memberData.name
                    : memberData.email;
                break;
              }
            }

            teamsMap.set(teamId, {
              teamId,
              teamName: teamData.name,
              lastActivity: submission.createdAt,
              teacherName,
              memberCount: Object.keys(teamMembers).length,
              questionId: submission.questionId,
              isAiGrading: submission.isAiGrading,
              submissionsInRange: 0,
              totalInputTokens: 0,
              totalOutputTokens: 0,
              aLaCarteCourses: [],
            });
          }
        }
      }

      for (const [teamId, team] of teamsMap) {
        team.submissionsInRange = submissionsCountMap.get(teamId) || 0;
        const tokenUsage = tokenUsageMap.get(teamId) || {
          inputTokens: 0,
          outputTokens: 0,
        };
        team.totalInputTokens = tokenUsage.inputTokens;
        team.totalOutputTokens = tokenUsage.outputTokens;
        // Add accumulated aLaCarteCourses to team
        team.aLaCarteCourses = Array.from(
          aLaCarteCoursesMap.get(teamId) || new Set()
        );
      }

      const teamsArray = Array.from(teamsMap.values());
      setTeamsActivity(teamsArray);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching teams activity:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeamsActivity(queryParams);
  }, [queryParams]);

  const formatDate = (timestamp) => {
    if (!timestamp) return "-";
    const date = timestamp.toDate();
    return new Date(date).toLocaleString(undefined, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZoneName: "short",
    });
  };

  const handleFilterChange = (event) => {
    setFormValues((prev) => ({
      ...prev,
      aiGrading: event.target.value,
    }));
  };

  const handleStartDateChange = (event) => {
    setFormValues((prev) => ({
      ...prev,
      startDate: event.target.value,
    }));
  };

  const handleEndDateChange = (event) => {
    setFormValues((prev) => ({
      ...prev,
      endDate: event.target.value,
    }));
  };

  const handleSearch = () => {
    setQueryParams(formValues);
  };

  if (loading) {
    return (
      <div
        style={{ display: "flex", justifyContent: "center", padding: "20px" }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ padding: "20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 3,
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        <h1>Teams Activity</h1>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <TextField
            label="Start Date"
            type="datetime-local"
            value={formValues.startDate}
            onChange={handleStartDateChange}
            sx={{ width: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="End Date"
            type="datetime-local"
            value={formValues.endDate}
            onChange={handleEndDateChange}
            sx={{ width: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>AI Grading Filter</InputLabel>
            <Select
              value={formValues.aiGrading}
              onChange={handleFilterChange}
              label="AI Grading Filter"
            >
              <MenuItem value="all">All Submissions</MenuItem>
              <MenuItem value="true">AI Grading Only</MenuItem>
              <MenuItem value="false">Non-AI Grading Only</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            onClick={handleSearch}
            startIcon={<SearchIcon />}
            sx={{ height: 56 }}
          >
            Search
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Team Name</TableCell>
              <TableCell>Teacher</TableCell>
              <TableCell>Last Activity</TableCell>
              <TableCell>Members</TableCell>
              <TableCell>Question ID</TableCell>
              <TableCell>AI Grading</TableCell>
              <TableCell>Submissions (In Range)</TableCell>
              <Tooltip title="Total input tokens used across all submissions in range">
                <TableCell>Input Tokens</TableCell>
              </Tooltip>
              <Tooltip title="Total output tokens used across all submissions in range">
                <TableCell>Output Tokens</TableCell>
              </Tooltip>
              <TableCell>À La Carte Courses</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teamsActivity.map((team) => (
              <TableRow
                key={team.teamId}
                component={Link}
                to={`/teams-activity/${team.teamId}`}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                  textDecoration: "none",
                  "& td": {
                    color: "inherit",
                  },
                }}
              >
                <TableCell>{team.teamName}</TableCell>
                <TableCell>{team.teacherName || "-"}</TableCell>
                <TableCell>{formatDate(team.lastActivity)}</TableCell>
                <TableCell>{team.memberCount}</TableCell>
                <TableCell>{team.questionId}</TableCell>
                <TableCell>{team.isAiGrading ? "Yes" : "No"}</TableCell>
                <TableCell>{team.submissionsInRange}</TableCell>
                <TableCell>{team.totalInputTokens.toLocaleString()}</TableCell>
                <TableCell>{team.totalOutputTokens.toLocaleString()}</TableCell>
                <TableCell>
                  {team.aLaCarteCourses &&
                    team.aLaCarteCourses.map((course, index) => (
                      <Chip
                        key={index}
                        label={course}
                        size="small"
                        sx={{ margin: "2px" }}
                      />
                    ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
