import React, { useEffect, useState } from "react";
import { useParams, Link, useLocation, useHistory } from "react-router-dom";
import { db } from "../../utils/firebase";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function TeamDetailsPage() {
  const { teamId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [teamDetails, setTeamDetails] = useState(null);
  const [submissions, setSubmissions] = useState([]);
  const [members, setMembers] = useState([]);

  // Get isAiGrading from URL query params
  const queryParams = new URLSearchParams(location.search);
  const aiGradingFilter = queryParams.get("isAiGrading") || "all";

  const handleFilterChange = (event) => {
    const value = event.target.value;
    const params = new URLSearchParams(location.search);
    if (value === "all") {
      params.delete("isAiGrading");
    } else {
      params.set("isAiGrading", value);
    }
    history.push(`${location.pathname}?${params.toString()}`);
  };

  useEffect(() => {
    const fetchTeamDetails = async () => {
      try {
        setLoading(true);

        // Fetch team details
        const teamDoc = await db.collection("teams").doc(teamId).get();
        if (!teamDoc.exists) {
          console.error("Team not found");
          return;
        }

        const teamData = teamDoc.data();
        setTeamDetails(teamData);

        // Process members
        const membersData = [];
        for (const [memberId, memberData] of Object.entries(
          teamData.teamMembers || {}
        )) {
          membersData.push({
            id: memberId,
            isAdmin: memberId === teamData.userId,
            ...memberData,
          });
        }

        // Sort members: admins first, then alphabetically by name
        const sortedMembers = membersData.sort((a, b) => {
          if (a.isAdmin !== b.isAdmin) {
            return a.isAdmin ? -1 : 1;
          }
          return (a.name || "").localeCompare(b.name || "");
        });

        setMembers(sortedMembers);

        // Fetch recent submissions (last 100)
        let query = db
          .collection("teamsQuestionSubmissions")
          .where("teamId", "==", teamId)
          .orderBy("createdAt", "desc");

        // Apply AI grading filter if set
        if (aiGradingFilter !== "all") {
          query = query.where("isAiGrading", "==", aiGradingFilter === "true");
        }

        query = query.limit(100);
        const submissionsSnapshot = await query.get();

        const submissionsData = submissionsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSubmissions(submissionsData);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching team details:", error);
        setLoading(false);
      }
    };

    fetchTeamDetails();
  }, [teamId, aiGradingFilter]);

  const formatDate = (timestamp) => {
    if (!timestamp) return "-";
    return timestamp.toDate().toLocaleString();
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" p={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={4}>
      <Box display="flex" alignItems="center" mb={4}>
        <Button
          component={Link}
          to="/teams-activity"
          startIcon={<ArrowBackIcon />}
          sx={{ mr: 2 }}
        >
          Back
        </Button>
        <Typography variant="h4" component="h1">
          {teamDetails?.name || "Team Details"}
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Team Members
        </Typography>
        <Paper>
          <List>
            {members.map((member, index) => (
              <React.Fragment key={member.id}>
                <ListItem>
                  <ListItemText
                    primary={
                      <Box display="flex" alignItems="center" gap={1}>
                        <span>{member.name}</span>
                        {member.isAdmin && (
                          <Chip
                            label="Admin"
                            size="small"
                            color="primary"
                            sx={{ height: 20 }}
                          />
                        )}
                      </Box>
                    }
                    secondary={member.email}
                  />
                </ListItem>
                {index < members.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h6">Recent Submissions</Typography>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>AI Grading Filter</InputLabel>
          <Select
            value={aiGradingFilter}
            onChange={handleFilterChange}
            label="AI Grading Filter"
          >
            <MenuItem value="all">All Submissions</MenuItem>
            <MenuItem value="true">AI Grading Only</MenuItem>
            <MenuItem value="false">Non-AI Grading Only</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Submission Time</TableCell>
              <TableCell>Question ID</TableCell>
              <TableCell>AI Grading</TableCell>
              <TableCell>Input Tokens</TableCell>
              <TableCell>Output Tokens</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {submissions.map((submission) => (
              <TableRow key={submission.id}>
                <TableCell>{formatDate(submission.createdAt)}</TableCell>
                <TableCell>{submission.questionId}</TableCell>
                <TableCell>{submission.isAiGrading ? "Yes" : "No"}</TableCell>
                <TableCell>
                  {submission.aiGradeDetails?.usage?.inputTokens?.toLocaleString() ||
                    "-"}
                </TableCell>
                <TableCell>
                  {submission.aiGradeDetails?.usage?.outputTokens?.toLocaleString() ||
                    "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
